import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";
var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;
import _Social2 from "./Social";
import _SocialElement2 from "./SocialElement";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2.default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Social", {
  enumerable: true,
  get: function () {
    return _Social.default;
  }
});
Object.defineProperty(exports, "SocialElement", {
  enumerable: true,
  get: function () {
    return _SocialElement.default;
  }
});
var _Social = _interopRequireDefault(_Social2);
var _SocialElement = _interopRequireDefault(_SocialElement2);
export default exports;
export const __esModule = exports.__esModule,
  Social = exports.Social,
  SocialElement = exports.SocialElement;